/* Feed page styles */
.feed-cont {
  width: 100%;
  max-width: 800px;
}

.feed-wrapper {
  margin-top: 20px;
}

.feed-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.feed-item {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.feed-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
}

.user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-details h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.user-role {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.post-time {
  margin: 0;
  font-size: 12px;
  color: #999;
}

.post-actions {
  position: relative;
}

.action-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size: 16px;
  color: #666;
}

.feed-item-content {
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 1.5;
}

.feed-item-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eee;
  padding-top: 15px;
}

.interaction-buttons {
  display: flex;
  gap: 15px;
}

.like-button,
.comment-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #666;
}

.like-button:hover,
.comment-button:hover {
  color: #1890ff;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.pagination button {
  padding: 5px 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

.pagination button:disabled {
  color: #d9d9d9;
  cursor: not-allowed;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 18px;
  color: #666;
}

/* View Feed Page Styles */
.feed-containers {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.feed-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.delete-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
}

.feed-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.feed-content {
  padding: 20px;
}

.feed-author {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.author-info h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.author-title {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.feed-text {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.6;
}

.feed-media {
  display: grid;
  grid-gap: 10px;
  margin-bottom: 20px;
}

.media-count-1 {
  grid-template-columns: 1fr;
}

.media-count-2 {
  grid-template-columns: 1fr 1fr;
}

.media-count-3,
.media-count-4 {
  grid-template-columns: 1fr 1fr;
}

.media-item {
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  height: 200px;
}

.media-item img,
.media-item video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feed-stats {
  display: flex;
  gap: 20px;
  border-top: 1px solid #eee;
  padding-top: 15px;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #666;
}

.feed-comments {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e0e0e0;

  h3 {
    font-size: 18px;
    margin-bottom: 15px;
    color: #333;
  }

  .comment-item {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 8px;

    .comment-author {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;

      .author-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 12px;
        object-fit: cover;
      }

      .author-info {
        h4 {
          margin: 0;
          font-size: 14px;
          font-weight: 600;
          color: #333;
        }

        p {
          margin: 2px 0;
          font-size: 12px;
          color: #666;
        }

        .comment-time {
          font-size: 11px;
          color: #888;
        }
      }
    }

    .comment-content {
      p {
        margin: 0;
        font-size: 14px;
        line-height: 1.5;
        color: #444;
      }
    }
  }
}

.comments-section {
  padding: 20px;
  border-top: 1px solid #eee;
}

.comments-section h3 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 18px;
}

.comment-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.no-comments {
  color: #999;
  font-style: italic;
}

.comment-item {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.comment-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
}

.comment-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.comment-content {
  flex: 1;
  position: relative;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.comment-header h4 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.comment-header span {
  font-size: 12px;
  color: #999;
}

.comment-content p {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}

.delete-comment-btn {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  cursor: pointer;
  color: #ff4d4f;
  font-size: 12px;
  padding: 5px;
}

.comment-form {
  display: flex;
  gap: 10px;
}

.comment-input {
  flex: 1;
  padding: 12px 15px;
  border: 1px solid #ddd;
  border-radius: 30px;
  font-size: 14px;
}

.comment-submit-btn {
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* Image Modal */
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.modal-content img {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
}

.close {
  position: absolute;
  top: -40px;
  right: 0;
  color: white;
  font-size: 30px;
  cursor: pointer;
}
