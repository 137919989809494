.roless {
  &-wrapper {
    padding: 10px;
  }
  &--heading {
    padding: 5px 25px;
    background: #ffffff;
    border-radius: 15px 15px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-img {
      display: flex;
      justify-content: flex-end;
      .formy {
        position: relative;
        &--box {
          width: 400px;
          // height: 60px;
          input {
            padding: 13px 25px;
            background: #ffffff;
            border: 0.3px solid #dfdfdf;
            border-radius: 4px;
            width: 100%;
            color: #c4c4c4;
            &:focus {
              outline: none;
            }
            &::placeholder {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 300;
              font-size: 18px;
              line-height: 27px;
              color: #dfdfdf;
            }
          }
          &-icon {
            position: absolute;
            top: 13px;
            left: 10px;
            i.fa.fa-search {
              color: #dfdfdf;
            }
          }
        }
      }
    }
    &-title {
      h2 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
      }
    }
  }
  &--tables {
    margin-top: 25px;
    &-heading {
      display: flex;
      flex-direction: column;
      &-title {
        padding: 20px 0;
        h2 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: #000000;
        }
      }
      &-box {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .formy {
          position: relative;
          &--box {
            width: 400px;
            // height: 60px;
            input {
              padding: 13px 25px;
              background: #ffffff;
              border: 0.3px solid #dfdfdf;
              border-radius: 4px;
              width: 100%;
              color: #c4c4c4;
              &:focus {
                outline: none;
              }
              &::placeholder {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 27px;
                color: #dfdfdf;
              }
            }
            &-icon {
              position: absolute;
              top: 13px;
              left: 10px;
              i.fa.fa-search {
                color: #dfdfdf;
              }
            }
          }
        }
        .clicks {
          display: flex;
          gap: 24px;
          align-items: center;
          &--right {
            button {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: center;
              color: #ffffff;
              background: #4895ef;
              border-radius: 8px;
              padding: 12px 24px;
              gap: 14px;
            }
            .modal-overlay {
              z-index: 9999;
              width: 80vw;
              height: 100vh;
              position: absolute;
              top: 0;
              left: 20%;
              background: rgba(0, 0, 0, 0.7);
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .modal-box {
              display: block;
              background: white;
              width: 60%;
              max-height: 80%;
              overflow-y: auto;
              scrollbar-width: none;
              &::-webkit-scrollbar {
                display: none;
              }
              padding: 1rem;
              border-radius: 1rem;
              &--wrapper {
                padding: 5px 20px;
                .heading {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  h1 {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    color: #000000;
                  }
                }
                .form-wrap {
                  margin-top: 20px;
                  &--module {
                    display: flex;
                    flex-direction: column;
                    &_cell {
                      margin: 10px;
                      width: 50%;
                    }
                    label {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 20px;
                      letter-spacing: -0.24px;
                      color: #666666;
                    }
                    input[type="text"],
                    input[type="number"],
                    #role {
                      width: 100%;
                      padding: 12px 20px;
                      margin: 8px 0;
                      display: inline-block;
                      background: #fafafa;
                      border: 0.3px solid #dfdfdf;
                      box-sizing: border-box;
                      border-radius: 5px;
                      -moz-appearance: textfield;
                      appearance: textfield;
                      &:focus {
                        outline: none;
                      }
                      &::placeholder {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 27px;
                        color: #dfdfdf;
                      }
                    }
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }
                  }
                  &--submodule {
                    display: flex;
                    flex-direction: row;
                    &_cell {
                      margin: 10px;
                      width: 50%;
                      &_i {
                        width: 100%;
                        margin: 10px;
                      }
                      #imageButton {
                        padding: 13px 10px;
                        background: #4895ef;
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                        font-family: "Poppins";
                        font-weight: 500;
                        font-size: 14px;
                        text-align: center;
                        color: #ffffff;
                        height: 46px;
                      }
                    }
                    label {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 20px;
                      letter-spacing: -0.24px;
                      color: #666666;
                    }
                    input[type="text"],
                    input[type="number"],
                    input[type="file"],
                    #module {
                      width: 100%;
                      max-height: 50px;
                      padding: 12px 20px;
                      margin: 8px 0;
                      display: inline-block;
                      background: #fafafa;
                      border: 0.3px solid #dfdfdf;
                      box-sizing: border-box;
                      border-top-left-radius: 5px;
                      border-bottom-left-radius: 5px;
                      -moz-appearance: textfield;
                      appearance: textfield;
                      &:focus {
                        outline: none;
                      }
                      &::placeholder {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 27px;
                        color: #dfdfdf;
                      }
                    }
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }
                  }
                  &--desc {
                    margin-top: 20px;
                    label {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 20px;
                      letter-spacing: -0.24px;
                      color: #666666;
                    }
                    textarea {
                      width: 100%;
                      height: 50%;
                      padding: 10px 10px 10px 15px;
                      margin: 8px 0;
                      display: inline-block;
                      background: #fafafa;
                      border: 0.5px solid #dfdfdf;
                      box-sizing: border-box;
                      border-radius: 5px;
                      resize: none;
                      &:focus {
                        outline: none;
                      }
                      &::placeholder {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 27px;
                        color: #dfdfdf;
                      }
                    }
                  }
                  &--bottom {
                    .btns {
                      margin-top: 15px;
                      &--wrap {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 24px;
                        .reset {
                          font-family: "Poppins";
                          font-style: normal;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 24px;
                          display: flex;
                          align-items: center;
                          text-align: center;
                          letter-spacing: 0.02em;
                          color: #4895ef;
                          padding: 12px 24px;
                          background: #e5f2fd;
                          border-radius: 8px;
                        }
                        .save {
                          font-family: "Poppins";
                          font-style: normal;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 24px;
                          display: flex;
                          align-items: center;
                          text-align: center;
                          letter-spacing: 0.02em;
                          color: #ffffff;
                          padding: 12px 24px;
                          background: #4895ef;
                          border-radius: 8px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &--left {
            button {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: center;
              color: #8c8c8c;
              background: #fff;
              border-radius: 8px;
              padding: 3px 12px;
              gap: 14px;
              border: 1px solid #fafafa;
            }
            .modal-overlay {
              z-index: 9999;
              width: 80vw;
              height: 100vh;
              position: absolute;
              top: 0;
              left: 20%;
              background: rgba(0, 0, 0, 0.7);
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .modal-box {
              display: block;
              background: white;
              width: 60%;
              //height: 50%;
              padding: 1rem;
              border-radius: 1rem;
              &--wrapper {
                padding: 5px 20px;
                .heading {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  h1 {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    color: #000000;
                  }
                }
                .form-wrap {
                  margin-top: 20px;
                  &--module {
                    display: flex;
                    flex-direction: column;
                    &_cell {
                      margin: 10px;
                      width: 50%;
                    }
                    label {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 20px;
                      letter-spacing: -0.24px;
                      color: #666666;
                    }
                    input[type="text"],
                    input[type="number"],
                    #role {
                      width: 100%;
                      padding: 12px 20px;
                      margin: 8px 0;
                      display: inline-block;
                      background: #fafafa;
                      border: 0.3px solid #dfdfdf;
                      box-sizing: border-box;
                      border-radius: 5px;
                      -moz-appearance: textfield;
                      appearance: textfield;
                      &:focus {
                        outline: none;
                      }
                      &::placeholder {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 27px;
                        color: #dfdfdf;
                      }
                    }
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }
                  }
                  &--submodule {
                    display: flex;
                    flex-direction: row;
                    &_cell {
                      margin: 10px;
                      width: 50%;
                      &_i {
                        width: 100%;
                      }
                    }
                    label {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 20px;
                      letter-spacing: -0.24px;
                      color: #666666;
                    }
                    input[type="text"],
                    input[type="number"],
                    input[type="file"],
                    #module {
                      width: 100%;
                      padding: 12px 20px;
                      margin: 8px 0;
                      display: inline-block;
                      background: #fafafa;
                      border: 0.3px solid #dfdfdf;
                      box-sizing: border-box;
                      border-radius: 5px;
                      -moz-appearance: textfield;
                      appearance: textfield;
                      &:focus {
                        outline: none;
                      }
                      &::placeholder {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 27px;
                        color: #dfdfdf;
                      }
                    }
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }
                  }
                  &--desc {
                    margin-top: 20px;
                    label {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 20px;
                      letter-spacing: -0.24px;
                      color: #666666;
                    }
                    textarea {
                      width: 100%;
                      height: 50%;
                      padding: 10px 10px 10px 15px;
                      margin: 8px 0;
                      display: inline-block;
                      background: #fafafa;
                      border: 0.5px solid #dfdfdf;
                      box-sizing: border-box;
                      border-radius: 5px;
                      resize: none;
                      &:focus {
                        outline: none;
                      }
                      &::placeholder {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 27px;
                        color: #dfdfdf;
                      }
                    }
                  }
                  &--bottom {
                    .btns {
                      margin-top: 15px;
                      &--wrap {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 24px;
                        .reset {
                          font-family: "Poppins";
                          font-style: normal;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 24px;
                          display: flex;
                          align-items: center;
                          text-align: center;
                          letter-spacing: 0.02em;
                          color: #4895ef;
                          padding: 12px 24px;
                          background: #e5f2fd;
                          border-radius: 8px;
                        }
                        .save {
                          font-family: "Poppins";
                          font-style: normal;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 24px;
                          display: flex;
                          align-items: center;
                          text-align: center;
                          letter-spacing: 0.02em;
                          color: #ffffff;
                          padding: 12px 24px;
                          background: #4895ef;
                          border-radius: 8px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.ant-modal-root {
  .ant-modal-content {
    // background: none !important;
    // border: none !important;
    // box-shadow: none !important;
    // width: 0;
    // height: 0;
    // position: relative;
    // bottom: 120px;
    // right: 70%;
    .ant-modal-body {
      // background: none !important;
      // border: none !important;
      // box-shadow: none !important;
      // width: 0;
      // height: 0;
      // position: relative;
      // top: 0;
      // left: 0;
      .clicks-mod {
        display: flex;
        gap: 14px;
        position: relative;
        bottom: 320px;
        right: 100%;

        &--right {
          button {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #ffffff;
            background: #4895ef;
            border-radius: 8px;
            padding: 12px 24px;
            gap: 14px;
          }
          .modal-overlay-mod {
            z-index: 9999;
            width: 80vw;
            height: 100vh;
            position: absolute;
            top: 0;
            left: 28%;
            // background: rgba(0, 0, 0, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .modal-box-mod {
            display: block;
            background: white;
            width: 60%;
            min-height: 38%;
            max-height: 80%;
            overflow-y: auto;
            scrollbar-width: none;
            &::-webkit-scrollbar {
              display: none;
            }
            padding: 1rem;
            border-radius: 1rem;
            &-int {
              display: block;
              background: white;
              width: 60%;
              min-height: 38%;
              max-height: 80%;
              // margin-top: -115px;
              overflow-y: auto;
              scrollbar-width: none;
              &::-webkit-scrollbar {
                display: none;
              }
              padding: 1rem;
              border-radius: 1rem;
            }
            &-cat {
              display: block;
              background: white;
              width: 60%;
              min-height: 40%;
              max-height: 50%;
              margin-top: 20px !important;
              max-height: 60%;
              overflow-y: auto;
              scrollbar-width: none;
              &::-webkit-scrollbar {
                display: none;
              }
              padding: 1rem;
              border-radius: 1rem;
            }
            &--wrapper {
              padding: 5px 20px;
              .heading {
                display: flex;
                justify-content: space-between;
                align-items: center;
                h1 {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 18px;
                  line-height: 27px;
                  color: #000000;
                }
              }
              .form-wrap {
                margin-top: 20px;
                &--module {
                  display: flex;
                  flex-direction: column;
                  &_cell {
                    margin: 10px;
                    width: 50%;
                  }
                  label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: -0.24px;
                    color: #666666;
                  }
                  input[type="text"],
                  input[type="number"],
                  #role {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #dfdfdf;
                    box-sizing: border-box;
                    border-radius: 5px;
                    -moz-appearance: textfield;
                    appearance: textfield;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                  input::-webkit-outer-spin-button,
                  input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }
                }
                &--submodule {
                  display: flex;
                  flex-direction: row;
                  &_cell {
                    margin: 10px;
                    width: 50%;
                    &_i {
                      margin: 0px 10px;
                      width: 100%;
                    }
                  }
                  #imageButton {
                    padding: 13px 10px;
                    background: #4895ef;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    font-family: "Poppins";
                    font-weight: 500;
                    font-size: 14px;
                    text-align: center;
                    color: #ffffff;
                    height: 46px;
                  }
                  label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: -0.24px;
                    color: #666666;
                  }
                  input[type="text"],
                  input[type="number"],
                  input[type="file"],
                  input[type="file"],
                  #module {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.3px solid #dfdfdf;
                    box-sizing: border-box;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    -moz-appearance: textfield;
                    appearance: textfield;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                  input::-webkit-outer-spin-button,
                  input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }
                }
                &--desc {
                  margin-top: 20px;
                  label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: -0.24px;
                    color: #666666;
                  }
                  textarea {
                    width: 100%;
                    height: 50%;
                    padding: 10px 10px 10px 15px;
                    margin: 8px 0;
                    display: inline-block;
                    background: #fafafa;
                    border: 0.5px solid #dfdfdf;
                    box-sizing: border-box;
                    border-radius: 5px;
                    resize: none;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 27px;
                      color: #dfdfdf;
                    }
                  }
                }
                &--category {
                  display: flex;
                  justify-content: space-between;
                  flex-direction: column;
                  gap: 16px;
                  width: 100%;
                  padding: 10px;
                  &_cat {
                    width: 50%;
                    // height: 100px;
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    h2 {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 20px;
                      color: #141414;
                    }
                    &-area {
                      display: flex;
                      gap: 8px;
                      border: 0.3px solid #d6d6d6;
                      border-radius: 8px;
                      padding: 10px;
                      .cat-image {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        width: 35px;
                        height: 35px;
                        img {
                          width: 100%;
                          height: 100%;
                          border-radius: 50%;
                        }
                      }
                      .cat-text {
                        display: flex;
                        align-items: center;
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                        color: #565656;
                      }
                    }
                  }
                  &_sub {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    h2 {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 20px;
                      color: #141414;
                    }
                    &-area {
                      display: flex;
                      width: 100%;
                      gap: 8px;
                      border-radius: 8px;
                      padding: 0px 10px 0px 0px;
                      .sub-text {
                        display: flex;
                        align-items: center;
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                        color: #565656;
                        border: 0.3px solid #d6d6d6;
                        border-radius: 8px;
                        background: transparent;
                      }
                      p {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                        color: #565656;
                      }
                    }
                  }
                }
                &--bottom {
                  .btns {
                    margin-top: 15px;
                    &--wrap {
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                      gap: 24px;
                      .reset {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        letter-spacing: 0.02em;
                        color: #4895ef;
                        padding: 12px 24px;
                        background: #e5f2fd;
                        border-radius: 8px;
                      }
                      .save {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        letter-spacing: 0.02em;
                        color: #ffffff;
                        padding: 12px 24px;
                        background: #4895ef;
                        border-radius: 8px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &--left {
          button {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #8c8c8c;
            background: #fafafa;
            border-radius: 8px;
            padding: 12px 24px;
            gap: 14px;
          }
        }
      }
    }
  }
}
