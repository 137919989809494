// Feed Styles
// feed.scss
.feed-container {
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;

  .feed-wrapper {
    max-width: 650px;
    // margin: 0 auto;

    .feed-items {
      position: relative;

      .feed-item {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
        overflow: hidden;

        .feed-item-header {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 16px;
          border-bottom: 1px solid #f0f0f0;

          .user-info {
            display: flex;
            align-items: flex-start;

            .user-avatar {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 12px;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .user-details {
              h4 {
                font-size: 16px;
                font-weight: 600;
                margin: 0 0 4px 0;
                color: #333;
              }

              .user-role {
                font-size: 13px;
                color: #666;
                margin: 0 0 2px 0;
              }

              .post-time {
                font-size: 12px;
                color: #999;
                margin: 0;
              }
            }
          }

          .post-actions {
            .action-button {
              background: none;
              border: none;
              cursor: pointer;
              color: #666;
              font-size: 18px;
              padding: 4px 8px;

              &:hover {
                color: #333;
              }
            }
          }
        }

        .feed-item-content {
          padding: 16px;

          p {
            font-size: 14px;
            line-height: 1.5;
            color: #333;
            margin: 0;
          }
        }

        .feed-item-footer {
          padding: 8px 16px;
          border-top: 1px solid #f0f0f0;

          .interaction-buttons {
            display: flex;
            align-items: center;

            button {
              background: none;
              border: none;
              cursor: pointer;
              display: flex;
              align-items: center;
              color: #666;
              margin-right: 20px;
              padding: 4px;

              i {
                margin-right: 4px;
                font-size: 16px;
              }

              span {
                font-size: 14px;
              }

              &:hover {
                color: #2962ff;
              }
            }
          }
        }

        .comment-input {
          padding: 12px 16px;
          border-top: 1px solid #f0f0f0;

          input {
            width: 100%;
            border: 1px solid #e0e0e0;
            border-radius: 20px;
            padding: 8px 16px;
            font-size: 14px;
            outline: none;

            &::placeholder {
              color: #999;
            }

            &:focus {
              border-color: #2962ff;
            }
          }
        }
      }

      .add-feed-button {
        position: fixed;
        bottom: 30px;
        right: 30px;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background-color: #2962ff;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease;
        text-decoration: none;

        i {
          font-size: 24px;
        }

        &:hover {
          background-color: darken(#2962ff, 10%);
          transform: translateY(-2px);
        }
      }
    }
  }
}

.feed-containers {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  .feed-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .back-button {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 16px;
      color: #3498db;
      background: transparent;
      border: none;
      cursor: pointer;
      font-size: 14px;
      transition: all 0.2s ease;

      &:hover {
        color: darken(#3498db, 10%);
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .feed-card {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
    overflow: hidden;
  }

  .feed-content {
    padding: 20px;

    .feed-author {
      display: flex;
      align-items: flex-start;
      margin-bottom: 16px;
      position: relative;

      .avatar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 12px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .author-info {
        flex: 1;

        .author-name {
          font-size: 16px;
          font-weight: 600;
          margin: 0 0 2px;
          color: #333;
        }

        .author-title {
          font-size: 14px;
          color: #666;
          margin: 0 0 4px;
        }

        .post-time {
          font-size: 12px;
          color: #999;
          margin: 0;
          display: flex;
          align-items: center;

          &::before {
            content: "";
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #ccc;
            margin-right: 6px;
          }
        }
      }

      .feed-options {
        .options-button {
          background: transparent;
          border: none;
          cursor: pointer;
          padding: 4px;
          color: #666;

          &:hover {
            color: #333;
          }
        }
      }
    }

    .feed-text {
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 1.5;
      color: #333;

      p {
        margin: 0 0 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    // .feed-media {
    //   display: grid;
    //   gap: 4px;
    //   margin-bottom: 16px;
    //   border-radius: 8px;
    //   overflow: hidden;

    //   &.media-count-1 {
    //     grid-template-columns: 1fr;
    //   }

    //   &.media-count-2 {
    //     grid-template-columns: repeat(2, 1fr);
    //   }

    //   &.media-count-3 {
    //     grid-template-columns: 2fr 1fr;
    //     grid-template-rows: 1fr 1fr;

    //     .media-item:first-child {
    //       grid-row: span 2;
    //     }
    //   }

    //   .media-item {
    //     position: relative;
    //     overflow: hidden;
    //     cursor: pointer;
    //     height: 0;
    //     padding-bottom: 100%;

    //     img,
    //     video {
    //       position: absolute;
    //       top: 0;
    //       left: 0;
    //       width: 100%;
    //       height: 100%;
    //       object-fit: cover;
    //     }

    //     &.video::after {
    //       content: "";
    //       position: absolute;
    //       top: 50%;
    //       left: 50%;
    //       transform: translate(-50%, -50%);
    //       width: 40px;
    //       height: 40px;
    //       background-color: rgba(0, 0, 0, 0.5);
    //       border-radius: 50%;
    //       background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FFFFFF'%3E%3Cpath d='M8 5v14l11-7z'/%3E%3C/svg%3E");
    //     }
    //   }
    // }
  }
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.pagination button {
  padding: 5px 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

.pagination button:disabled {
  color: #d9d9d9;
  cursor: not-allowed;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 18px;
  color: #666;
}

.feed-media.grid-media {
  display: grid;
  gap: 4px;
  position: relative;

  // Default to single column
  grid-template-columns: 1fr;

  // Media item styles
  .media-item {
    width: 100%;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    // Overlay for additional images
    &.media-overflow::after {
      content: attr(data-overflow);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: bold;
    }
  }

  // Styles for different numbers of media items
  &.media-1 {
    grid-template-columns: 1fr;
  }

  &.media-2 {
    grid-template-columns: 1fr 1fr;
  }

  &.media-3 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;

    .media-item:first-child {
      grid-column: span 2;
    }
  }

  &.media-4 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  &.media-5,
  &.media-6 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;

    .media-item:first-child {
      grid-column: span 3;
    }
  }

  &.media-more {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(3, 1fr);

    .media-item:first-child {
      grid-column: span 3;
    }
  }
}
